<template>
  <div >
    <div >
      <div class="subtabs" style="height: 2%; background: none">
        <input v-if="classRoom" type="radio" name="subtabs" id="AcademicsTab"  :checked="classRoom ? 'checked' : ''" />
        <label v-if="classRoom" for="AcademicsTab">Academics</label>
        <div v-if="classRoom" class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-4 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 26%">
                    <select
                      @change="getExamAcademicInfo(selectTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                  <span class="has-float-label" style="width: 50%">
                    <select
                      @change="getExamSubjectInfo(examInfoId)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="examInfoId"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in examAcademicList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Exam Term </label>
                  </span>

                
                </div>
              </div>
            </div>
          </div>
          
          <div class="form-row">
            <div class="form-group col-md-12 mt-3">
              <div class="tbllist mt-1">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell" style="width: 24%">Subject</div>
                    <div class="cell">Exam Date</div>
                    <div class="cell">Status</div>
                    <div class="cell">Total Marks</div>
                    <div class="cell">Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in examSubjectList"
                    :key="index"
                  >
                    <div class="col-1 cell">
                      {{ data.subjectName || '' }}
                    </div>
                    <div class="cell">
                      {{
                        data.examDate ? data.examDate.substring(0, 10) : "-"
                      }}
                    </div>
                    <div class="cell"> {{  data.isResultStatus || 'Draft' }}</div>
                    <div class="cell"> {{ data.totalMarks || '-' }}</div>
                    <div class="cell">
                      <button :disabled="!data.examDate || !data.totalMarks"
                        type="button"
                        style="width: 41%;height: 32px;"
                        class="btn btnsml"
                        id="svbtn"
                        @click="
                          openResultPopUp(
                            data._id,
                            data.subjectId,
                            data.examDate,
                            data.totalMarks,
                            index,
                            'academic',
                            data.subjectName
                          )
                        "
                      >
                        View Result
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="widjet"
                  v-if="examSubjectList.length == 0"
                  style="height: 198px; box-shadow: none"
                >
                  <div style="padding-top: 92px">
                    <span style="margin-left: 42%">No Data Found</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <input v-if="classRoom" type="radio" name="subtabs" id="CurricularActivities" />
        <label v-if="classRoom" for="CurricularActivities">Curricular Activities</label>
        <div v-if="classRoom" class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-4 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 26%">
                    <select
                      @change="getExamAcademicInfo(selectTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                  <span class="has-float-label" style="width: 50%">
                    <select
                      @change="getExamSubjectInfo(examInfoId)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="examInfoId"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in examAcademicList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Exam Term </label>
                  </span>

                  
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 mt-3">
              <div class="tbllist mt-1">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell" style="width: 24%">Subject</div>
                    <div class="cell">Exam Date</div>
                    <div class="cell">Status</div>
                    <div class="cell">Total Marks</div>
                    <div class="cell">Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in examSubjectCurricularList"
                    :key="index"
                  >
                  
                    <div class="col-1 cell">
                      {{ data.subjectName || '' }}
                    </div>
                    <div class="cell">
                      {{
                        data.examDate ? data.examDate.substring(0, 10) : "-"
                      }}
                    </div>
                    <div class="cell">{{  data.isResultStatus || 'Draft'  }}</div>
                    <div class="cell"> {{ data.totalMarks || '-' }}</div>
                    <!-- <div class="cell"></div> -->
                    <div class="cell">
                      <button :disabled="!data.examDate || !data.totalMarks"
                        type="button"
                        style="width: 41%;height: 33px;"
                        class="btn btnsml"
                        id="svbtn"
                        @click="
                          openResultPopUp(
                            data._id,
                            data.subjectId,
                            data.examDate,
                            data.totalMarks,
                            index,
                            'curricular',
                            data.subjectName
                          )
                        "
                      >
                        View Result
                      </button>
                      <!-- <button :disabled="!data.examDate || !data.totalMarks"
                        type="button"
                        style="width: 25%;height: 32px;"
                        class="btn btnsml"
                        id="svbtn"
                        @click="
                          openEvolateResultPopUp(
                            data._id,
                            data.subjectId,
                            data.examDate,
                            data.totalMarks
                          )
                        "
                      >
                      Evaluate
                      </button> -->
                    </div>
                  </div>
                </div>
                <div
                  class="widjet"
                  v-if="examSubjectCurricularList.length == 0"
                  style="height: 198px; box-shadow: none"
                >
                  <div style="padding-top: 92px">
                    <span style="margin-left: 42%">No Data Found</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input v-if="classRoom" type="radio" name="subtabs" id="Attendance"  />
        <label v-if="classRoom" for="Attendance">Attendance</label>
        <div v-if="classRoom" class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 12%">
                    <select
                      @change="getStudentAttendanceInfo()"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectAttendanceTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 pr-md-3">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Student Name</div>
                  <div class="cell">Total School Days</div>
                  <div class="cell">Present</div>
                  <div class="cell">Absent</div>
                 
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in studentAttendanceList"
                  :key="index"
                >
                  <div class="cell">
                    <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${data._id}`)"> {{ data.firstName + " " + data.lastName }}</a>

                   
                  </div>
                  <div class="cell" style="padding-left: 45px;">{{ data.student.length }}</div>
                  <div class="cell" style="padding-left: 23px;">{{ getPresentCount(data.student) }}</div>
                  <div class="cell" style="padding-left: 23px;">{{ getAbsentCount(data.student) }}</div>

                
                </div>
              </div>
              <div
                class="widjet"
                v-if="studentAttendanceList.length == 0"
                style="height: 198px; box-shadow: none"
              >
                <div style="padding-top: 92px">
                  <span style="margin-left: 42%">No Data Found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input v-if="classRoom" type="radio" name="subtabs" id="OverallPerformance" />
        <label v-if="classRoom" for="OverallPerformance">Overall Performance</label>
        <div v-if="classRoom" class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 12%">
                    <select
                      @change="getOverAllPerformanceInfo(selectPerformanceTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectPerformanceTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 pr-md-3">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell" style="text-align:center">Student Name</div>
                  <div class="cell" style="text-align:center">Academics</div>
                  <div class="cell" style="text-align:center">Curricular</div>
                  <div class="cell" style="text-align:center;width: 13%;" v-for="(item, index) in editColumnList" :key="index">{{  item.label  }}</div>
                  <div class="cell" style="text-align:center">Attendance</div>

                  <div class="cell" style="text-align:center">Overall Grade </div>

                  <!-- <div class="cell">Ranking</div> -->
                  <!-- <div class="cell">Action</div> -->
                </div>
                <div class="resrow" v-for="(data, index) in Object.keys(overallPerformanceDetails)" :key="index">
                 <!-- {{ data }} -->
                 <div class="col-1 cell" style="text-align:center">{{ overallPerformanceDetails[data][0].contact.firstName + " " +  overallPerformanceDetails[data][0].contact.lastName}} </div>
                 <div class="col-1 cell" style="text-align:center">{{ getAcademicStudentMarks(data) > 0 ? getAcademicStudentMarks(data) + " / " + getAcademicTotalMarks(data) : 0 + " / " + getAcademicTotalMarks(data) }} </div>
                 <div class="col-1 cell" style="text-align:center" >{{ getCurricularStudentMarks(data) > 0 ? getCurricularStudentMarks(data) + " / " + getCurricularTotalMarks(data) : 0 + " / " + getCurricularTotalMarks(data) }} </div>
                 <!-- <div class="col-1 cell" style="text-align:center" v-else>-- </div> -->
                 <div class="cell" v-if="!contributionType" style="text-align:center;width: 13%;" v-for="(item, indexcc) in editColumnList" :key="indexcc">{{  getOverallStudentContribution(data,item.value) + " / " + item.totalmark  }}</div>
                 <div class="cell" v-if="contributionType" style="text-align:center;width: 13%;" v-for="(item, indexccg) in editColumnList" :key="indexccg">{{  getOverallStudentGradeContribution(data,item.value)  }}</div>

                 <div class="col-1 cell" style="text-align:center">{{  getStudentAttendance(data) }} </div>
                 <!-- <div class="cell"  v-if="editColumnList.length > 0" style="text-align:center;width: 13%;" >ss
                  <span v-for="(item, indexc) in editColumnList" :key="indexc" >  {{  getOverallStudentGradeWithHeaders(data,item.value,item.totalmark)  }}</span>
                 
                </div> -->
          
                 <div class="col-1 cell" style="text-align:center">{{ getOverallStudentGradeWithHeaders(data) }}</div>
                 <!-- <div class="col-1 cell">{{ data }} </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <input v-if="schoolSettingDetails && schoolSettingDetails.isClassRanking" type="radio" name="subtabs" id="Ranking" :checked="classId && !classRoom ? 'checked' : ''" />
        <label v-if="schoolSettingDetails && schoolSettingDetails.isClassRanking" for="Ranking">Ranking</label>
      
        <div class="tab" v-if="schoolSettingDetails && schoolSettingDetails.isClassRanking">
          <div class="mt-1 flexitm" style="right: 0px;margin-right: 33px;position: absolute;top: 76px;">
                <button
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click.prevent="downloadTable"
                >
                  <i class="fas fa-download"></i>
                 
                  <span class="tooltiptext">Download</span>
                </button>
              

               
              </div>
          <!-- <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 12%">
                    <select
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectPerformanceTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <button type="button" class="btn btnsml" id="svbtn" style="width: 10%;"
                   
                    @click.prevent="downloadTable">
                    
                  </button> -->
                  <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 12%">
                    <select
                      @change="getOverAllRankingInfo(selectRankTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectRankTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in termList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 pr-md-3">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell" style="text-align:center">Student Name</div>
                  <div class="cell" style="text-align:center">Class & ClassRoom</div>
                  <div class="cell" style="text-align:center">Total Marks</div>
                  <div class="cell" style="text-align:center">Rank</div>

                  <!-- <div class="cell">Ranking</div> -->
                  <!-- <div class="cell">Action</div> -->
                </div>
                <div class="resrow" v-for="(data, index) in studentRankList" :key="index">
                    <div class="col-1 cell" style="text-align:center">
                      <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${data._id._id}`)">{{  data._id && data._id.firstName ? data._id.firstName + " " + data._id.lastName : '' }} </a>

                       </div>
                       <div class="col-1 cell" style="text-align:center">{{  data._id && data._id.firstName ? getClassName(data._id.class,data._id.classSection) : '' }} </div>

                       <div class="col-1 cell" style="text-align:center">{{  data.mark | '-' }} </div>
                    <div class="col-1 cell" style="text-align:center">{{  data.rank }} </div>
                  </div>
                <!-- <div class="resrow" v-for="(data, index) in studentRankList" :key="index">
                                   <div class="col-1 cell" style="text-align:center">{{  getStudentAttendance(data) }} </div>

                 <div class="col-1 cell" style="text-align:center">{{ overallPerformanceDetails[data][0].contact.firstName + " " +  overallPerformanceDetails[data][0].contact.lastName}} </div>
                 <div class="col-1 cell" style="text-align:center">{{ getAcademicStudentMarks(data) > 0 ? getAcademicStudentMarks(data) + " / " + getAcademicTotalMarks(data) : 0 + " / " + getAcademicTotalMarks(data) }} </div>
                 <div class="col-1 cell" style="text-align:center" >{{ getCurricularStudentMarks(data) > 0 ? getCurricularStudentMarks(data) + " / " + getCurricularTotalMarks(data) : 0 + " / " + getCurricularTotalMarks(data) }} </div>
                 <div class="cell" style="text-align:center;width: 13%;" v-for="(item, indexcc) in editColumnList" :key="indexcc">{{  getOverallStudentContribution(data,item.value) + " / " + item.totalmark  }}</div>
                 <div class="col-1 cell" style="text-align:center">{{  getStudentAttendance(data) }} </div>
               
          
                 <div class="col-1 cell" style="text-align:center">{{ getOverallStudentGradeWithHeaders(data) }}</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;">Please Select ClassRoom...</span>
          </div>
        </div> -->
   

    <b-modal id="examResultPopUp" style="width: 100%">
      <div class="row" style="height: 502px;">
        <div class="col-lg-12">
          <div class="widjet">
            <div style="text-align: center;">
              Subject Name: <span style="font-weight: bold;">{{ subjectName }}</span>
            </div>
            <form
              data-vv-scope="crtAdhocFeeValidate"
              id="crtfeeplans"
              accept-charset="utf-8"
            >
              <div class="tbllist mt-1" style="height: 460px;">
                <div class="restable" style="text-align: center">
                  <div class="resrow resheader">
                    <div class="cell col-3">Student Name</div>
                    <div class="cell col-1">Grade</div>
                    <div class="cell col-2">Total Exam Marks</div>

                    <!-- <div class="cell">%</div> -->
                    <div class="cell col-1">Marks</div>
                    <div class="cell col-1">Absent/Present</div>
                    <div class="cell col-4">Comments</div>
                  </div>

                  <div
                    class="resrow"
                    v-for="(data, index) in saveContactResultList"
                    :key="index"
                  >
                    <div class="cell">{{ data.firstName + " " + data.lastName }}</div>
                    <div class="cell">{{ data.grade || "--" }}</div>

                    <div class="cell">{{ data.totalMarks || "--" }}</div>

                    <!-- <div class="cell"> {{ data.percentage ? data.percentage +' '+ '%' : '--' }}</div> -->
                    <!-- <div class="cell"></div> -->
                    <div class="cell">
                      <input
                      ref="inputFieldmark"
                      @keyup.enter="handleEnterKeyMark(index, 'mark')"
                      
                        type="number"
                        :min="0"
                        :name="index + 'checkmark'"
                        v-validate="{
                          required: false,
                          max_value: termTotalMarks,
                        }"
                        :max="termTotalMarks"
                        :disabled="!data.isAbsent"
                        :style="
                          !data.isAbsent ? 'background-color: #f7f7f7;' : ''
                        "
                        class="form-control form-input text-center"
                        v-model="data.mark"
                       
                      />
                      <span
                        class="required"
                        v-if="
                          errors.first(
                            `crtAdhocFeeValidate.${index + 'checkmark'}`
                          )
                        "
                      >
                        Maximum Marks {{ termTotalMarks }}</span
                      >
                    </div>
                    <div class="cell">
                      <label
                        class="switch"
                        style="margin-left: 14px; margin-bottom: -10px"
                      >
                        <input
                          type="checkbox"
                          id="togBtn"
                          @change="clearMarkValue(data.isAbsent, index)"
                          v-model="data.isAbsent"
                        />
                        <div class="slider"></div>
                      </label>
                    </div>
                    <div class="cell">
                      <input 
                        ref="inputField"
                      
                        class="form-control form-input"
                        @keyup.enter="handleEnterKey(index, 'comment')"
                        v-model="data.comment"
                       
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="widjetfooter text-center dkicon">
              <button
                type="button"
                class="btn btnsml"
                @click="saveExamAcademicResult"
                id="svbtn"
              >
                  <span>Save</span>
              </button>
              <button style="width: 16%;"
                type="button"
                class="btn btnsml"
                @click="publishExamAcademicResult"
                id="svbtn"
              >
                  <span>Save / Publish</span>
              </button>
              <button
                type="button"
                class="btn btncl clsmdl"
                @click="closeResultPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
   
    <b-modal id="addAttendancePopUp" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closeAttendancePopUp">×</button>

      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Add Attendance</h2>
        <br />
        <div>
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    disabled
                    v-model="saveStudentAttendanceDetails.date"
                    type="date"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <!-- <span class="has-float-label"> -->
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Present/Absent ?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      v-model="saveStudentAttendanceDetails.isAbsent"
                      type="checkbox"
                      id="sibtogBtn"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
                <!-- </span> -->
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <textarea
                    v-model="saveStudentAttendanceDetails.comment"
                    name="comment"
                    class="form-control form-input"
                    style="min-height: 40px; resize: none"
                    id="tocmts"
                  ></textarea>
                  <label for="clsnameid"
                    >comment <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group col-md-3 mt-3">
                <button
                  @click="saveAttendance"
                  :disabled="
                    !saveStudentAttendanceDetails.comment ||
                    !saveStudentAttendanceDetails.date
                  "
                  style="width: 25%"
                  type="button"
                  class="btn btnsml"
                >
                  Save
                </button>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 mt-3 pr-md-3">
                <div class="tbllist mt-4">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Date</div>
                      <div class="cell">Present/Absent</div>
                      <div class="cell">comment</div>
                      <div class="cell">Action</div>
                    </div>
                    <div
                      class="resrow"
                      v-for="(data, index) in studentAttendanceDetails.student"
                      :key="index"
                    >
                      <div class="cell" data-title="Teacher">
                        {{ data.date }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        {{ data.isAbsent ? "Present" : "Absent" }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        {{ data.comment }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        <button
                          @click.prevent="confirmDelete(data._id, data.index)"
                          class="btn round red tooltipt"
                        >
                          <i class="far fa-trash-alt"></i>
                          <span class="tooltiptext">Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->

              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeAttendancePopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  name: "teacher",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    sectionListView: {
      type: Array,
    },
    classListView: {
      type: Array,
    }
  },
  data() {
    return {
      saveContactResultList: [],
      studentRankList: [],
      subjectName: '',
      editColumnList: [],
      termTotalMarks: null,
      examInfoId: null,
      updateStatusIndex: null,
      updateStatusType: '',
      schoolSettingDetails: {},
      selectPerformanceTerm: null,
      selectRankTerm: null,
      selectAttendanceTerm: null,
      selectOverAllPerformanceTerm: null,
      overallPerformanceDetails: {},
      examAcademicDetails: {
        subject: null,
        startDate: null,
        endDate: null,
        totalMarks: null,
        type: "academic",
      },
      examCurricularDetails: {
        subject: null,
        curricularActivitiesName: "",
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "curricularActivitie",
      },
      minAcademicDate: null,
      maxAcademicDate: null,
      minCurriculumDate: null,
      maxCurriculumDate: null,
      selectTerm: null,
      contributionType: false,
      subjectList: [],
      termList: [],
      allGradeList: {},
      studentContributionList: [],
      examSubjectList: [],
      examSubjectCurricularList: [],
      examAcademicList: [],
      examCurricularList: [],
      studentAttendanceList: [],
      examId: null,
      examStartDate: null,
      examSubjectId: null,
      studentAttendanceDetails: {},
      saveStudentAttendanceDetails: {
        date: new Date(),
        isAbsent: false,
        comment: "",
      },
    };
  },

  created() {
    this.getGradeSetupDetails();

    this.getSubjectList();
    this.initComp()
    this.getClassTerm();
   // this.getOverAllPerformanceInfo(null)
    // this.getContributionHeaders()
  //  this.getStudentContributions()
    // this.getOverAllRankingInfo()
    this.getAccountDetails()

  // eventBus.$on("callOverallPerformance", (data) => {
  //     if(data){
  //       this.getOverAllPerformanceInfo(null)
  //     this.getContributionHeaders()
  //     this.getStudentContributions()
  //     }
     
  //   });
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    checkAttendance() {
      if (
        this.studentAttendanceDetails &&
        this.studentAttendanceDetails.student
      ) {
        let details = this.studentAttendanceDetails.student.find(
          (x) => x.date == new Date()
        );
        if (details) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }

      //this.studentAttendanceDetails.student
    },
   
    getSubjectAll() {
      if (this.subjectList.length > 0) {
        return this.subjectList.filter(
          (x) => x.isCurricularActivities == false
        );
      } else {
        return 0;
      }
    },
    getSubjectCurricularActivities() {
      if (this.subjectList.length > 0) {
        return this.subjectList.filter((x) => x.isCurricularActivities == true);
      } else {
        return [];
      }
    },
  },
  // mounted() {

  //   // eventBus.$on("callOverallPerformance", (data) => {
  //   //   if()
  //   //   this.getOverAllPerformanceInfo(null)
  //   //   this.getContributionHeaders()
  //   //   this.getStudentContributions()
  //   // });
    
  // },

  methods: {
   
    async initComp(){
     await this.getClassTerm();
     await this.getOverAllPerformanceInfo(null)
     await this.getOverAllRankingInfo()
     await this.getContributionHeaders()
     await this.getContributionAction()


    },
    getClassName(classid,sectionid){
      let classdata = this.classListView.find((x) => x._id == classid);
      
      if(classdata && classdata._id){
        let sectiondata = classdata.sections.filter((x) => x._id == sectionid);
       
        if(sectiondata){
          return classdata.className + " / " +  sectiondata[0].name
        }
      }else{
        return '-'
      }



    },
    async handleEnterKey(rowIndex, columnName) {

      const nextRowIndex = rowIndex + 1;
      const nextColumnName = columnName;

      if (this.saveContactResultList[nextRowIndex]) {
        this.$nextTick(() => {

        const nextInputField = this.$refs.inputField[nextRowIndex];

        if (nextInputField) {
          nextInputField.focus();
        }
      });
      }
    },
    async handleEnterKeyMark(rowIndex, columnName) {
      const nextRowIndex = rowIndex + 1;
      const nextColumnName = columnName;

      if (this.saveContactResultList[nextRowIndex]) {
        
        this.$nextTick(() => {

        const nextInputField = this.$refs.inputFieldmark[nextRowIndex];

        if (nextInputField) {
          nextInputField.focus();
        }
      });
      }
    },
    redirectRoute(vipath) {
      localStorage.setItem("activeTab",'/admission/home');
      localStorage.setItem("previousTab",this.$route.path);

      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    getExamSubjectInfo(id){
      let details = this.examAcademicList.find((x) => x._id == id);
      if (details) {
        if(details.examSubject.length > 0){
          this.examSubjectList = details.examSubject.filter( x => x.isCurricularActivities == false)
          this.examSubjectCurricularList = details.examSubject.filter( x => x.isCurricularActivities)

        }
     //   this.examSubjectList = details.examSubject || []
         // return false;
        } else {
          this.examSubjectList = []
          this.examSubjectCurricularList = []
         // return true;
        }
    },
    // moveToNextRow(currentRowIndex, fieldName) {
    //   // Check if the current row is the last row
    //   if (currentRowIndex < this.saveContactResultList.length - 1) {
    //   }
    // },
    getAbsentCount(data) {
      if (data.length > 0) {
        let details = data.filter((x) => x.status == 'Absent' || ( x.isDailyAttendance && x.status == 'Leave'));
        return details.length;
      } else {
        return 0;
      }
    },
    getPresentCount(data) {
      if (data.length > 0) {
        let details = data.filter((x) => x.status == 'Present');
        return details.length;
      } else {
        return 0;
      }
    },
    getAcademicStudentMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){
            let filterExamType = getStudentAcademicResult.filter( x => x.isExamEvaluated && x.isReport && x.isCurricularActivities == false)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => o.mark)
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    getAcademicTotalMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){

      
           let filterExamType = getStudentAcademicResult.filter( x => x.isExamEvaluated && x.isReport && x.isCurricularActivities == false)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => Number(o.totalMarks))
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    getOverallStudentGrade(value){
     
      let studentTotalMarks = 0
      let examTotalMarks = 0

      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){

            examTotalMarks = getStudentAcademicResult.map((o) => Number(o.totalMarks)).reduce((a, c) => { return a + c; });
            studentTotalMarks = getStudentAcademicResult.map((o) => o.mark).reduce((a, c) => { return a + c; });

      }
     
     let avg  = ((studentTotalMarks/(examTotalMarks)) * 100).toFixed(2)

     let roundOfAvg = Math.round(avg)

     const filteredStudents = this.allGradeList.gradeMarkList.filter(x => roundOfAvg >= x.from && roundOfAvg <= x.to);
     
     if(filteredStudents.length > 0){
      return filteredStudents[0].grade
     }else{
      return '-'
     }
   
    },
    getOverallStudentGradeWithHeaders(value){
     
     let studentTotalMarks = 0
     let examTotalMarks = 0

     let getStudentAcademicResult = this.overallPerformanceDetails[value]

     if(getStudentAcademicResult.length > 0){
          let filterExamType = getStudentAcademicResult.filter( x => x.isExamEvaluated && x.isReport)
          if(filterExamType.length > 0){

          
           examTotalMarks = filterExamType.map((o) => Number(o.totalMarks)).reduce((a, c) => { return a + c; });
           studentTotalMarks = filterExamType.map((o) => o.mark).reduce((a, c) => { return a + c; });
          }
     }
     if(!this.contributionType){
      if(this.editColumnList.length > 0){
        if(!this.contributionType){
        let details = this.studentContributionList.find( x => x.studentId == value)

        if(details){
          for(let x of this.editColumnList){
            let mark = details[x.value] || 0

            if(mark){
              studentTotalMarks = studentTotalMarks + Number(mark)

            }

          // studentTotalMarks = studentTotalMarks + mark
            if(x.totalmark){
              examTotalMarks = examTotalMarks + x.totalmark
            }
          

          }
        }
        // else{
        //   console.log("value",value)

        //   let details = this.studentContributionList.find( x => x.studentId == value)

        //   console.log("this.studentContributionList",this.studentContributionList)
        // }
        }
      }
    
    let avg  = ((studentTotalMarks/(examTotalMarks)) * 100).toFixed(2)

    let roundOfAvg = Math.round(avg)

    const filteredStudents = this.allGradeList.gradeMarkList.filter(x => roundOfAvg >= x.from && roundOfAvg <= x.to);
    
    if(filteredStudents.length > 0){
     return filteredStudents[0].grade
    }else{
     return '-'
    }
     }

     if(this.contributionType){
      let gradeSumValue = 0
      let gradeSubjectValue = 0

      if(this.editColumnList.length > 0){
        if(this.contributionType){
        let details = this.studentContributionList.find( x => x.studentId == value)

        if(details){
          for(let x of this.editColumnList){
            let grade = details[x.value] || ''

            if(grade){
                const filteredStudents = this.allGradeList.gradeMarkList.filter(x => x.grade == grade.toString());
                if(filteredStudents.length > 0){
                  gradeSumValue = gradeSumValue + filteredStudents[0].scale
                }


            }



          }
        }
       
        }
      }

      let avg1  = ((studentTotalMarks/(examTotalMarks)) * 100).toFixed(2)

    let roundOfAvg1 = Math.round(avg1)

    const filteredStudents1 = this.allGradeList.gradeMarkList.filter(x => roundOfAvg1 >= x.from && roundOfAvg1 <= x.to);
    
    if(filteredStudents1.length > 0){
      gradeSumValue = gradeSumValue + filteredStudents1[0].scale

     //return filteredStudents[0].scale
    }

    let totallength = this.editColumnList.length + 1

    let avg  = ((gradeSumValue/(totallength))).toFixed(2)

    let roundOfAvg = Math.round(avg)


    const filteredStudents = this.allGradeList.gradeMarkList.filter(x => roundOfAvg == x.scale);
    
    if(filteredStudents.length > 0){
     return filteredStudents[0].grade
    }else{
     return '-'
    }
        // return '-'

     }
  
   },
    
    getCurricularStudentMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){
            let filterExamType = getStudentAcademicResult.filter( x => x.isExamEvaluated && x.isReport && x.isCurricularActivities)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => o.mark)
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    getStudentTotalAttendance(value) {
     // let getStudentAcademicResult = this.overallPerformanceDetails[value]
     let getStudentAcademicResult = this.studentAttendanceList.filter( x => x._id == value)[0]

      if(getStudentAcademicResult.student.length > 0){
        return getStudentAcademicResult.student.length
        
      }else{
        return ''
      }
      
    },
    getStudentAttendance(value){
      
      let getStudentAcademicResult = this.studentAttendanceList.filter( x => x._id == value)[0]

      if(getStudentAcademicResult && getStudentAcademicResult.student.length > 0){

        let presentData = getStudentAcademicResult.student.filter((x) => x.status == 'Present');

        let count = presentData.length
        let dd  = ((count/(getStudentAcademicResult.student.length)) * 100).toFixed(2)
        return Math.round(dd) + ' ' + '%'

      //  let details = getStudentAcademicResult.student.filter((x) => x.status == 'Present');

      //  let filterPresntData = getStudentAcademicResult.filter( x => x.isAbsent)

       // return details.length
        
      }else{
        return '0 %'
      }
      
    },
    getCurricularTotalMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){
            let filterExamType = getStudentAcademicResult.filter( x => x.isExamEvaluated && x.isReport && x.isCurricularActivities)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => Number(o.totalMarks))
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    async callfromParentCmp() {
      
      this.getOverAllPerformanceInfo(null)
      this.getOverAllRankingInfo(null)
      this.getContributionHeaders()
      this.getContributionAction()
    },
    
    async getClassTerm() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/class/getAllTerm?classId=${this.classId}`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);

          this.termList = data || [];
          if (this.termList.length > 0) {

            if (this.termList.length == 1) {
            this.selectTerm = this.termList[0]._id;
            this.getExamAcademicInfo(this.termList[0]._id);
            this.selectRankTerm = this.termList[0]._id,
            this.selectAttendanceTerm = this.termList[0]._id || null,
            this.selectOverAllPerformanceTerm = this.termList[0]._id || null
            this.selectPerformanceTerm = this.termList[0]._id;
            this.getStudentAttendanceInfo();

            // var startDate = new Date(this.termList[0].startDate);
            // var endDate = new Date(this.termList[0].endDate);

            // this.minDate = this.dateFormat(startDate)
        
            // this.maxDate =  this.dateFormat(endDate)
            }else{
            let currentDate = new Date()

            let checkData = this.termList.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
            if(checkData.length > 0){

              this.selectTerm = checkData[0]._id;
              this.selectPerformanceTerm = checkData[0]._id;
              this.selectRankTerm = this.termList[0]._id,
            this.selectAttendanceTerm = this.termList[0]._id || null,
            this.selectOverAllPerformanceTerm = this.termList[0]._id || null
              this.getExamAcademicInfo(checkData[0]._id);
              this.getStudentAttendanceInfo();

              // var startDate = new Date(checkData[0].startDate);
              // var endDate = new Date(checkData[0].endDate);

              // this.minDate = this.dateFormat(startDate)
          
              // this.maxDate =  this.dateFormat(endDate)

            }else{
              this.selectTerm = this.termList[0]._id;
              this.selectPerformanceTerm = this.termList[0]._id;
              this.selectRankTerm = this.termList[0]._id,
              this.selectAttendanceTerm = this.termList[0]._id || null,
              this.selectOverAllPerformanceTerm = this.termList[0]._id || null
              this.getExamAcademicInfo(this.termList[0]._id);
              this.getStudentAttendanceInfo();

              // var startDate = new Date(this.termList[0].startDate);
              // var endDate = new Date(this.termList[0].endDate);

              // this.minDate = this.dateFormat(startDate)
          
              // this.maxDate =  this.dateFormat(endDate)
            }
           
            }
          }
          //            this.getExamAcademicInfo(this.termList[0]._id);

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    getSubjectName(id) {
      if (id) {
        if (this.subjectList.length > 0) {
          let details = this.subjectList.filter((x) => x._id == id);
          if (details.length > 0) {
            return details[0].subjectName;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountSettingConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
           
           this.schoolSettingDetails = details
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      //  this.viLoader = false;
      }
    },
    async getContributionHeaders() {
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          term: this.selectPerformanceTerm
        }
       
        const response = await ViService.viXPost(
          "/academic/getContributionHeaders",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.editColumnList = []

          this.$toasted.success(response.message);
          
          if(response.data.length > 0){
             this.editColumnList = response.data
          }
        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getContributionAction() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          section: this.classRoom
        }
       
        const response = await ViService.viXPost(
          "/academic/getContributionAction",
          obj,
          userData.token
        );

        if (response.isSuccess) {   

           if(response.data.isMark){
            this.contributionType = false,

            this.getStudentContributions()
           }
           if(response.data.isGrade){
            this.contributionType = true,

            this.getStudentGradeContributions()
           }

        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getStudentGradeContributions() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          section: this.classRoom,
          term: this.selectPerformanceTerm
        }

        const response = await ViService.viXPost(
          "/academic/getStudentGradeContributions",
          obj,
          userData.token
        );

        if (response.isSuccess) {   
          this.studentContributionList = response.data.contributions

        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getStudentContributions() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          section: this.classRoom,
          term: this.selectPerformanceTerm
        }
        
       
        const response = await ViService.viXPost(
          "/academic/getStudentContributions",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.studentContributionList = response.data.contributions
        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    getOverallStudentContribution(studentId,data){
     let details = this.studentContributionList.find( x => x.studentId == studentId)

     if(details){
      return details[data] || 0
     }else{
      return 0
     }
    },
    getOverallStudentGradeContribution(studentId,data){
     let details = this.studentContributionList.find( x => x.studentId == studentId)
   
     if(details){
      return details[data] || ''
     }else{
      return ''
     }
    },
    
    async getSubjectList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async saveExamAcademic() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = false;
        if (this.examAcademicList.length > 0) {
          let details = this.examAcademicList.find((x) => x.subject == this.examAcademicDetails.subject);
          if (details) {
            allowSave = false;
          } else {
            allowSave = true;
          }
        } else {
          allowSave = true;
        }
        if (allowSave) {
          this.examAcademicDetails["class"] = this.classId;
          this.examAcademicDetails["classroom"] = this.classRoom;
          this.examAcademicDetails["term"] = this.selectTerm;

          const response = await ViService.viXPost(
            "/academic/examAcademic/save",
            this.examAcademicDetails,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeAcademicPopUp();
            this.getExamAcademicInfo(this.selectTerm);
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          this.$toasted.error("Subject Already Created");
        }
      }
    },
    async saveExamCurriculur() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.examCurricularDetails["class"] = this.classId;
        this.examCurricularDetails["classroom"] = this.classRoom;
        this.examCurricularDetails["term"] = this.selectTerm;

        const response = await ViService.viXPost(
          "/academic/examAcademic/save",
          this.examCurricularDetails,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.closeCurricularPopUp();
          this.getExamAcademicInfo(this.selectTerm);
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async confirmDelete(id, index) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/academic/studentAttendance/delete/${id}`,
          userData.token
        );

        if (response.isSuccess) {
          //this.getCurriculumInfo(this.selectTerm);
          this.studentAttendanceDetails.student.splice(index, 1);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    downloadTable() {

      // let obj = {
      //     class: this.classId,
      //     section: this.classRoom
      // }
      let fileName = ''

      let classdata = this.classListView.find((x) => x._id == this.classId);
      
      if(classdata && classdata._id && this.classRoom){
        let sectiondata = classdata.sections.filter((x) => x._id == this.classRoom);
       
        if(sectiondata){
          fileName = classdata.className + "  " +  sectiondata[0].name
        }
      }else{
        fileName = classdata.className
      }
      // else{
      //   return '-'
      // }
      let data = []

      for(let x of this.studentRankList){
        data.push({
          name: x._id.firstName + " " + x._id.lastName,
          class: this.getClassName(x._id.class,x._id.classSection),
          mark: x.mark,
          rank: x.rank
        })
      }

    const csvContent = data.reduce((acc, item) => {
      const row = Object.values(item).join(',');
      return `${acc}${row}\n`;
    }, 'STUDENT NAME,CLASS & CLASSROOM,TOTAL MARKS,RANK\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${fileName}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert('Your browser does not support downloading files.');
    }
  },
    async saveExamAcademicResult() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          updateData: this.saveContactResultList,
          class: this.classId,
         // classroom: this.classRoom,
          term: this.selectTerm,
          exam: this.examId,
          totalMarks: this.termTotalMarks,
          examStartDate: this.examStartDate,
          subject: this.examSubjectId,
        };

        const response = await ViService.viXPost(
          "/academic/examAcademic/result/save",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.closeResultPopUp();
          this.getStudentAttendanceInfo();
        } else {
          this.$toasted.error(response.message);
        }
      }
     }
    },
    async publishExamAcademicResult() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let checkvalue = this.saveContactResultList.filter( x => x.isAbsent && (x.mark == null || x.mark == '' || x.mark == undefined) )

        if(checkvalue.length == 0){
        let obj = {
          updateData: this.saveContactResultList,
          class: this.classId,
         // classroom: this.classRoom,
          term: this.selectTerm,
          exam: this.examId,
          totalMarks: this.termTotalMarks,
          examStartDate: this.examStartDate,
          subject: this.examSubjectId,
        };

        const response = await ViService.viXPost(
          "/academic/examAcademicPublishResult/save",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          if(this.updateStatusType == 'academic'){
           this.examSubjectList[this.updateStatusIndex]['isResultStatus'] = 'Published'
          }
          if(this.updateStatusType == 'curricular'){
            this.examSubjectCurricularList[this.updateStatusIndex]['isResultStatus'] = 'Published'

          }
          this.getStudentAttendanceInfo();
          this.getOverAllPerformanceInfo()
          this.getOverAllRankingInfo()
         
          this.closeResultPopUp();

          // this.getExamSubjectInfo(this.examInfoId)
         // this.getExamAcademicInfo(this.selectTerm);

        } else {
          this.$toasted.error(response.message);
        }
      }else{
        this.$toasted.error("Please enter all marks and do the action");

      }
      }
     }
    },
    
    async getGradeSetupDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       
        const response = await ViService.viXPost(
          "/academic/getGradeSetupDetails",
          { type: 'all'},
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          const data = secureUI.secureGet(response.data);
          this.allGradeList = data
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async saveAttendance() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const checkCurrentDateAttendance =
          this.studentAttendanceDetails.student.filter(
            (x) => x.date == this.saveStudentAttendanceDetails.date
          );
        if (checkCurrentDateAttendance.length == 0) {
          // this.saveStudentAttendanceDetails['class'] = this.classId,
          // this.saveStudentAttendanceDetails['class'] = this.classRoom,
          // this.saveStudentAttendanceDetails['student'] =  this.studentAttendanceDetails._id

          let obj = {
            class: this.classId,
            classroom: this.classRoom,
            date: this.saveStudentAttendanceDetails.date,
            isAbsent: this.saveStudentAttendanceDetails.isAbsent,
            comment: this.saveStudentAttendanceDetails.comment,
            student: this.studentAttendanceDetails._id,
          };

          const response = await ViService.viXPost(
            "/academic/studentAttendance/save",
            obj,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            // this.saveStudentAttendanceDetails
            this.saveStudentAttendanceDetails.isAbsent = false;
            this.saveStudentAttendanceDetails.comment = "";
            //   isAbsent: false,
            //   comment: ''
            // }

            this.studentAttendanceDetails.student.push({
              _id: response.data._id,
              date: response.data.date,
              isAbsent: response.data.isAbsent,
              comment: response.data.comment,
            });
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          this.$toasted.error(
            `${this.saveStudentAttendanceDetails.date} Already Entered Attendace`
          );
        }
      }
    },

    async getExamAcademicInfo(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.examSubjectList = [],
        this.examSubjectCurricularList = []
        if (id) {
          let requestBody = {
            class: this.classId,
            term: id,
          };

          const response = await ViService.viXPost(
            "/academic/getExamAcademicPublishedList",
            requestBody,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);
            this.examAcademicList = resultData

            if(this.examAcademicList.length > 0){
              
              let details = this.examAcademicList[this.examAcademicList.length - 1]

              if(details){
                this.examInfoId = details._id
                this.getExamSubjectInfo(this.examInfoId)
              }
             // this.examInfoId
            }
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          (this.examAcademicList = []), (this.examCurricularList = []);
        }
      }
    },
    async getStudentAttendanceInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          class: this.classId,
          classroom: this.classRoom,
          term: this.selectAttendanceTerm,
        };

        const response = await ViService.viXPost(
          "/academic/getStudentAttendanceInfo",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.studentAttendanceList = resultData;
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    clearMarkValue(data, index) {
  
      if (!data) {
        this.saveContactResultList[index].mark = null;
      }
    },

    async getExamAcademicStudentInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          term: this.selectTerm,
          class: this.classId,
          examSubjectId: this.examSubjectId,
          exam: this.examId,
          classroom: this.classRoom
        };

        const response = await ViService.viXPost(
          "/academic/getExamAcademicStudentInfo",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
         // this.saveContactResultList = resultData.examResultData
          if (resultData.examResultData.length > 0) {
            for (let x of resultData.examResultData) {
              this.saveContactResultList.push({
                _id: x._id,
                mark: x.mark,
                grade: x.grade,
                percentage: x.percentage || "",
                totalMarks: x.totalMarks || "",
                student: x.contact._id,
                firstName: x.contact.firstName,
                lastName: x.contact.lastName,
                isAbsent: x.isAbsent == false ? false : true,
                comment: x.comment,
              });
            }
          } 
          //else {
          //   for (let x of resultData.contactList) {
          //     this.saveContactResultList.push({
          //       grade: "",
          //       mark: 0,
          //       student: x._id,
          //       firstName: x.firstName,
          //       lastName: x.lastName,
          //       isAbsent: false,
          //       comment: "",
          //     });
          //   }
          // }
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async getEvolateExamAcademicStudentInfo(examaaSubjectId,exaaamId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
         // term: this.selectTerm,
          class: this.classId,
          examSubjectId: examaaSubjectId,
          exam: exaaamId,
        };

        const response = await ViService.viXPost(
          "/academic/updateExamevolateExamAcademicStudentInfo",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getOverAllPerformanceInfo()
          this.getOverAllRankingInfo()
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async getOverAllPerformanceInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          term: this.selectPerformanceTerm,
          class: this.classId,
          classroom: this.classRoom
        };

        const response = await ViService.viXPost(
          "/academic/getOverAllPerformanceResult",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
console
          this.overallPerformanceDetails = resultData
         
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async getOverAllRankingInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          term: this.selectRankTerm,
          class: this.classId,
          classroom: this.classRoom
        };

        const response = await ViService.viXPost(
          "/academic/getOverRankingResult",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.studentRankList = resultData
          //this.overallPerformanceDetails = resultData
         
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
   


    openAcademicPopUp() {
      this.$bvModal.show("addAcademicPopUp");
    },
    closeAcademicPopUp() {
      this.$bvModal.hide("addAcademicPopUp");
      this.examAcademicDetails = {
        subject: null,
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "academic",
      };
    },
    openCurricularPopUp() {
      this.$bvModal.show("addCurricularPopUp");
    },
    closeCurricularPopUp() {
      this.$bvModal.hide("addCurricularPopUp");
      this.examCurricularDetails = {
        subject: null,
        curricularActivitiesName: "",
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "curricularActivitie",
      };
    },
    openAttendancePopUp(data) {
      let currentDate = new Date();

      let startmonth = currentDate.getMonth() + 1;
      let startdate = currentDate.getDate();

      if (startdate < 10) {
        startdate = "0" + startdate;
      }
      if (startmonth < 10) {
        startmonth = "0" + startmonth;
      }

      this.saveStudentAttendanceDetails.date =
        currentDate.getFullYear() + "-" + startmonth + "-" + startdate;

      this.studentAttendanceDetails = data;
      this.$bvModal.show("addAttendancePopUp");
    },
    closeAttendancePopUp() {
      this.$bvModal.hide("addAttendancePopUp");
    },

    openResultPopUp(id, subId, date,mark,index,type,subjectName) {
      this.updateStatusIndex = index
      this.updateStatusType = type
      this.subjectName = subjectName || ''
      this.termTotalMarks = null
      this.termTotalMarks = mark
      this.examId = id;
      this.examSubjectId = subId,
      this.examStartDate = date
        this.$bvModal.show("examResultPopUp");
      this.getExamAcademicStudentInfo();
    
    },
    openEvolateResultPopUp(id, subId, date,mark){
      // this.termTotalMarks = null
      // this.termTotalMarks = mark
      // this.examId = id;
      // (this.examSubjectId = subId),
      //   (this.examStartDate = date),
      //   this.$bvModal.show("examResultPopUp");
      this.getEvolateExamAcademicStudentInfo(subId,id);
    },
    closeResultPopUp() {
      this.updateStatusIndex = null,
      this.updateStatusType = '',
      this.termTotalMarks = null
      this.examId = null;
      (this.examStartDate = null), (this.examSubjectId = null);
      this.$bvModal.hide("examResultPopUp");
      this.saveContactResultList = [];
    },
  },
};
</script>
